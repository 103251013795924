const WALLET_CONNECT_CLOUD_PROJECT_ID = 'b1b915089c9379d3a1d1bd001f8e6040';
const WALLET_CONNECT_APP_METADATA = {
    name: 'Arx Wallet App',
    description: 'HaLo Client for Wallet Connect',
    url: 'arx.org',
    icons: []
};

export {WALLET_CONNECT_CLOUD_PROJECT_ID, WALLET_CONNECT_APP_METADATA};

